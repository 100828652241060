import { add } from "date-fns/add";
import { addDays } from "date-fns/addDays";
import { endOfMonth } from "date-fns/endOfMonth";
import { format } from "date-fns/format";
import { formatISO9075 } from "date-fns/formatISO9075";
import { previousSaturday } from "date-fns/previousSaturday";
import { startOfMonth } from "date-fns/startOfMonth";
import { sub } from "date-fns/sub";
import { subDays } from "date-fns/subDays";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { logError } from "common/util/consoleHelpers";

import { formatDateByGranularity, formatWeekDate, SpecialDateFormat } from "./formatHelpers";
import { normalizeDate } from "./utcDateFromString";

export const QUARTERS_AS_MONTH_RANGES = [
  "January - March",
  "April - June",
  "July - September",
  "October - December"
];
export const QUARTERS_AS_ABBREVIATED_MONTH_RANGES = [
  "Jan - Mar",
  "Apr - Jun",
  "Jul - Sep",
  "Oct - Dec"
];

export const dateToIsoString = (date: Date) => formatISO9075(date, { representation: "date" });
export const dateFromIsoString = (date: string) => normalizeDate(new Date(date));

const currentDate = (date?: string) => {
  if (date) {
    return normalizeDate(new Date(date));
  }
  return normalizeDate(new Date());
};

const convertDates = ({ startsOn, endsOn }: { startsOn: Date; endsOn: Date }) => {
  return {
    startsOn: dateToIsoString(startsOn) ?? null,
    endsOn: dateToIsoString(endsOn) ?? null
  };
};

export const lastWeekDates = (date?: string) => {
  const dateObj = currentDate(date);
  const endsOn = date ? dateObj : sub(previousSaturday(dateObj), { weeks: 0 });
  const startsOn = sub(endsOn, { days: 6 });
  return convertDates({ endsOn, startsOn });
};

export const lastMonthDates = (date?: string, weeks = 1, months = 1) => {
  const dateObj = currentDate(date);
  const weekStartDate = sub(dateObj, { weeks });
  const startsOn = date
    ? startOfMonth(weekStartDate)
    : startOfMonth(sub(weekStartDate, { months }));
  const endsOn = endOfMonth(startsOn);
  return convertDates({ endsOn, startsOn });
};

export const weekStartDate = (date: Date) => {
  return subDays(date, 6);
};

export const weekEndDate = (date: Date) => {
  return addDays(date, 6);
};

export const weekStartAndEndDates = ({
  start,
  end,
  formatAsString = false
}: {
  start?: Date;
  end?: Date;
  formatAsString?: boolean;
}) => {
  let dates;
  if (start && !end) {
    dates = { startDate: start, endDate: weekEndDate(start) };
  } else if (!start && end) {
    dates = { startDate: weekStartDate(end), endDate: end };
  } else {
    throw new Error("weekStartAndEndDates function given incorrect parameters");
  }
  if (dates && formatAsString) {
    const granularity = MhcTimeSeriesGranularityEnum.Week;
    return [
      formatDateByGranularity({ value: dates.startDate, granularity }),
      formatDateByGranularity({ value: dates.endDate, granularity })
    ].join(" - ");
  }
  if (!dates) throw new Error("weekStartAndEndDates function did not return dates");
  return dates;
};

export const dateToMs = (value: number): number => value * 1000;
export const dateAsTime = (date: number | string) => {
  if (typeof date === "string") {
    return normalizeDate(new Date(date)).getTime();
  } else {
    return dateToMs(date);
  }
};

type FormatQuarterDateOptions = {
  useQuarterAbbreviation?: boolean;
  useFullMonthName?: boolean;
  includeYear?: boolean;
};
/**
 * Format a given date as quarter
 *
 * @param quarterStartDate - Start date of quarter
 * @param options
 * @param options.useQuarterAbbreviation - Format date with quarter abbreviation (e.g. "Q1")
 * @param options.useFullMonthName - Use full month name when formatting quarter month range
 * @param options.includeYear - include year in result (e.g. ", 2024")
 *
 * @returns formatted string for quarter based on options
 */
export const formatQuarterDate = (
  quarterStartDate: Date,
  options: FormatQuarterDateOptions = {}
) => {
  const { useQuarterAbbreviation = false, useFullMonthName = false, includeYear = false } = options;
  if (useQuarterAbbreviation) {
    return format(quarterStartDate, `QQQ${includeYear ? ", yyyy" : ""}`);
  }

  let formatString = "MMM";
  if (useFullMonthName) {
    formatString = "MMMM";
  }

  const yearFormat = includeYear ? ", yyyy" : "";

  const startMonth = format(quarterStartDate, formatString + yearFormat);
  const endMonth = format(add(quarterStartDate, { months: 2 }), formatString + yearFormat);

  return `${startMonth} - ${endMonth}`;
};

export const formatDateTime = (
  date?: Date,
  granularity?: MhcTimeSeriesGranularityEnum | undefined,
  overrideYearFormat?: Intl.DateTimeFormatOptions["year"],
  overrideMonthFormat?: Intl.DateTimeFormatOptions["month"],
  specialFormat?: SpecialDateFormat
) => {
  if (!date) {
    return undefined;
  }
  try {
    const dateTimeOptions: Intl.DateTimeFormatOptions = {
      year: overrideYearFormat ? overrideYearFormat : "numeric",
      day: "numeric",
      month: "numeric",
      timeZone: "UTC"
    };
    if (
      granularity === MhcTimeSeriesGranularityEnum.Year ||
      granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow
    ) {
      dateTimeOptions.day = undefined;
      dateTimeOptions.month = undefined;
    }
    if (granularity === MhcTimeSeriesGranularityEnum.Month) {
      dateTimeOptions.month = overrideMonthFormat ?? "long";
      dateTimeOptions.day = undefined;
    }
    if (granularity === MhcTimeSeriesGranularityEnum.Week) {
      return formatWeekDate({ value: date, specialFormat });
    }
    if (granularity === MhcTimeSeriesGranularityEnum.Quarter) {
      return formatQuarterDate(date);
    }
    const formatter = new Intl.DateTimeFormat("en-US", dateTimeOptions);
    return formatter.format(date);
  } catch (error) {
    logError(error, `Error formatting date on report dates`, `Date given: ${date.toUTCString()}`);
    return undefined;
  }
};
