import { Box } from "@mui/material";

import { NAV_MARGIN, SITE_HEADER_HEIGHT } from "layout/configuration";

import { ExternalSites } from "./ExternalSites";
import { BrandBarHome } from "./Home";

export const BrandBar: React.FC = () => {
  return (
    <Box
      className="web-only-no-pdf"
      component="header"
      sx={{
        backgroundColor: "brand.main",
        height: SITE_HEADER_HEIGHT,
        py: "10px",
        px: NAV_MARGIN,
        position: "relative",
        zIndex: 1000,
        display: "flex",
        flexDirection: "row"
      }}
    >
      <BrandBarHome />
      <ExternalSites />
    </Box>
  );
};
