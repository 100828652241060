"use client";

import { ReactNode, useEffect } from "react";
import { Box } from "@mui/material";

import { SiteContentContainer } from "../common/components/SiteContentContainer";
import DashboardContentContainer from "./DashboardContent/DashboardContentContainer";
import useSideNavToggle from "./hooks/useSideNavToggle";
import { BrandBar } from "./BrandBar";
import { EnvWarningBanner } from "./EnvWarningBanner";
import SiteFooter from "./SiteFooter";
import { SiteTopNav } from "./SiteTopNav";

interface DefaultPageProps {
  variant: "default";
  children: ReactNode;
  masthead?: ReactNode;
  sidebar?: never;
  header?: ReactNode;
  contextHeader?: never;
  image?: undefined;
  title?: undefined;
  defaultSideNavOpenDesktop?: boolean;
  defaultSideNavOpenMobile?: boolean;
}

interface DashboardPageProps {
  variant: "dashboard";
  children: ReactNode;
  masthead?: never;
  sidebar: ReactNode;
  header: ReactNode;
  contextHeader?: ReactNode;
  image?: undefined;
  title?: undefined;
  defaultSideNavOpenDesktop?: boolean;
  defaultSideNavOpenMobile?: boolean;
}

interface StoryMapPageProps {
  variant: "story_map";
  children: ReactNode;
  masthead?: never;
  sidebar: ReactNode;
  header?: never;
  contextHeader?: ReactNode;
  image: StaticImageData;
  title?: string;
  defaultSideNavOpenDesktop?: boolean;
  defaultSideNavOpenMobile?: boolean;
}

interface DashboardNoSidebarPageProps extends Omit<DashboardPageProps, "variant" | "sidebar"> {
  variant: "dashboard-no-sidebar";
  sidebar?: never;
}

export type MainLayoutProps =
  | DefaultPageProps
  | DashboardPageProps
  | DashboardNoSidebarPageProps
  | StoryMapPageProps;

export const MainLayout: React.FC<MainLayoutProps> = ({
  variant,
  children,
  sidebar,
  masthead,
  header,
  contextHeader,
  image,
  title,
  defaultSideNavOpenDesktop = true
}) => {
  const {
    desktopNavIsOpen,
    mobileNavIsOpen,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    handleCloseDesktopMenu,
    handleOpenDesktopMenu,
    setDesktopNavState
  } = useSideNavToggle();
  useEffect(() => {
    setDesktopNavState(defaultSideNavOpenDesktop);
  }, [defaultSideNavOpenDesktop, setDesktopNavState]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <EnvWarningBanner />
      <BrandBar />
      <SiteTopNav onClickHamburger={handleOpenMobileMenu} />
      {variant === "default" && (
        <>
          {masthead}
          {header}
          <SiteContentContainer maxWidth={variant === "default" ? false : undefined} sx={{ pt: 5 }}>
            {children}
          </SiteContentContainer>
        </>
      )}

      {variant !== "default" && (
        <DashboardContentContainer
          desktopNavIsOpen={desktopNavIsOpen}
          mobileNavIsOpen={mobileNavIsOpen}
          handleCloseMobileMenu={handleCloseMobileMenu}
          handleCloseDesktopMenu={handleCloseDesktopMenu}
          handleOpenDesktopMenu={handleOpenDesktopMenu}
          sidebar={sidebar}
          header={header}
          contextHeader={contextHeader}
          image={image}
          title={title}
        >
          {children}
        </DashboardContentContainer>
      )}
      <SiteFooter />
    </Box>
  );
};
