import { ReactElement } from "react";
import AddIcon from "@mui/icons-material/Add";
import { BoxProps, Button, ButtonProps } from "@mui/material";

interface Props {
  onClick: ButtonProps["onClick"];
  icon?: ReactElement;
  sx?: BoxProps["sx"];
}

const FooterToggle: React.FC<Props> = ({ onClick, icon = <AddIcon />, sx = {} }) => (
  <Button
    sx={{
      ...sx,
      alignSelf: "flex-end",
      backgroundColor: "brand.main",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: "none",
      fontSize: 30,
      height: 40,
      minWidth: "auto",
      color: "white",
      px: 1.25,
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "brand.main"
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: "brand.main"
      }
    }}
    variant="contained"
    onClick={onClick}
  >
    {icon}
  </Button>
);

export default FooterToggle;
