"use client";

import { useCallback } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import { sideNavOpenAtom } from "common/atoms/sideNavOpenAtom";

export const sideNavDesktopOpenAtom = atom<boolean>({
  key: "SIDE_NAV_DESKTOP_OPEN_ATOM",
  default: true
});

export const sideNavMobileOpenAtom = atom<boolean>({
  key: "SIDE_NAV_MOBILE_OPEN_ATOM",
  default: false
});

const useSideNavToggle = () => {
  const [desktopNavIsOpen, setDesktopNavState] = useRecoilState(sideNavDesktopOpenAtom);
  const [mobileNavIsOpen, setMobileNavState] = useRecoilState(sideNavMobileOpenAtom);
  const sideNavStateSetter = useSetRecoilState(sideNavOpenAtom);
  const handleCloseMobileMenu = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Side Nav",
      action: "Close",
      label: "Mobile"
    });
    setMobileNavState(false);
  }, [setMobileNavState]);
  const handleOpenMobileMenu = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Side Nav",
      action: "Open",
      label: "Mobile"
    });
    setMobileNavState(true);
  }, [setMobileNavState]);
  const handleCloseDesktopMenu = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Side Nav",
      action: "Close",
      label: "Desktop"
    });
    sideNavStateSetter(false);
    setDesktopNavState(false);
  }, [setDesktopNavState, sideNavStateSetter]);
  const handleOpenDesktopMenu = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Side Nav",
      action: "Open",
      label: "Desktop"
    });
    sideNavStateSetter(true);
    setDesktopNavState(true);
  }, [setDesktopNavState, sideNavStateSetter]);

  return {
    desktopNavIsOpen,
    mobileNavIsOpen,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    handleCloseDesktopMenu,
    handleOpenDesktopMenu,
    setDesktopNavState,
    setMobileNavState
  };
};

export default useSideNavToggle;
