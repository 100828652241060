"use client";

import { usePathname } from "next/navigation";
import { Link, Typography } from "@mui/material";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

interface TopNavLinkProps {
  href: string;
  title: string;
}

export const TopNavLink = ({ href, title }: TopNavLinkProps) => {
  const pathname = usePathname();
  const selected = pathname == href;

  return (
    <Link
      href={href}
      onClick={() =>
        sendGaNavigationEvent({ category: "Headers", action: "Top Nav Link Click", label: title })
      }
      sx={({ palette }) => ({
        color: selected ? palette.nav.main : palette.nav.light,
        display: "flex",
        fontSize: "14px",
        padding: ".5rem 2.5rem",
        borderBottom: `4px solid ${selected ? palette.nav.main : "transparent"}`,
        "&:hover": {
          borderBottom: `4px solid ${selected ? palette.nav.main : "#dee2e6"}`
        },
        width: "min-content",
        lineBreak: "normal",
        whiteSpace: "nowrap"
      })}
      underline="none"
    >
      <Typography
        component="span"
        sx={{
          fontSize: "inherit",
          my: "auto"
        }}
        fontWeight={600}
      >
        {title}
      </Typography>
    </Link>
  );
};
