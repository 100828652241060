"use client";

import { AppBar, Box } from "@mui/material";

import { HEADER_Z_INDEX } from "../configuration/zIndex";
import { NAV_MARGIN, SITE_HEADER_HEIGHT } from "layout/configuration";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import useSideNavToggle from "layout/hooks/useSideNavToggle";
import { SiteContentContainer } from "../../common/components/SiteContentContainer";
import MenuButton from "component/button/MenuButton";
import { SiteTopNavMHCLogo } from "./MyHealthyCommunityLogo";
import { SiteTopNavLinks } from "./SiteTopNavLinks";
import { SiteTopNavSearch } from "./SiteTopNavSearch";

interface Props {
  onClickHamburger?: VoidFunction;
  searchEnabled?: boolean;
}

export const SiteTopNav: React.FC<Props> = ({ onClickHamburger, searchEnabled = true }) => {
  const isMobile = useIsMobile();
  const { handleOpenMobileMenu } = useSideNavToggle();
  const handleClick = onClickHamburger ?? handleOpenMobileMenu;
  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        zIndex: HEADER_Z_INDEX
      }}
    >
      <SiteContentContainer
        sx={{
          minHeight: SITE_HEADER_HEIGHT,
          maxHeight: SITE_HEADER_HEIGHT,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          py: "0",
          px: NAV_MARGIN,
          flexDirection: "row",
          width: "100%",
          flexShrink: "0",
          bgcolor: "common.white",
          borderBottomWidth: 1,
          borderBottomColor: "borders.light",
          borderBottomStyle: "solid"
        }}
        maxWidth={false}
        disableGutters={true}
      >
        <SiteTopNavMHCLogo />
        <SiteTopNavLinks />
        {!isMobile && searchEnabled ? (
          <SiteTopNavSearch />
        ) : (
          <Box
            sx={{
              display: isMobile ? "none" : "block",
              minWidth: isMobile ? "none" : 400
            }}
          />
        )}
        {isMobile && onClickHamburger && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box sx={{ ml: { md: 4 }, mr: { xs: -1, md: 0 } }}>
              <MenuButton handleClick={handleClick} />
            </Box>
          </Box>
        )}
      </SiteContentContainer>
    </AppBar>
  );
};
