export const DEFAULT_COLUMN_SPACING = 8;
export const DEFAULT_SKELETON_ANIMATION = "wave";
export const DEFAULT_MARGIN = 40;
export const DEFAULT_MARGIN_STR = `${DEFAULT_MARGIN}px`;
export const DEFAULT_MARGIN_SMALL_SCREEN = DEFAULT_MARGIN / 2;
export const DEFAULT_MARGIN_SMALL_SCREEN_STR = `${DEFAULT_MARGIN_SMALL_SCREEN}px`;
export const SITE_HEADER_HEIGHT = 60;
export const SITE_FOOTER_HEIGHT = 90;
export const NAV_MARGIN = 2.5;
export const DEFAULT_SKELETON_STYLE = {
  borderRadius: "6px"
};

export const defaultContainerMargin = {
  xs: DEFAULT_MARGIN_SMALL_SCREEN_STR,
  md: DEFAULT_MARGIN_STR
};

export const defaultGridSpacing = { ...defaultContainerMargin };

export const defaultCardMargin = {
  xs: `-${DEFAULT_MARGIN_SMALL_SCREEN_STR}`,
  md: 0
};

export const defaultCardPadding = {
  xs: DEFAULT_MARGIN_SMALL_SCREEN_STR,
  md: DEFAULT_MARGIN_STR
};

export const DEFAULT_LAYOUT_GAP = {
  xs: 5,
  md: 5
};
